import { mapGetters } from "vuex";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
export default {
  mixins: [redirectToLearningSpace],
  data() {
    return {
      tabs: [],
      tabIndex: 0,
      isLoading: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setTabContent();
    });
    this.getInProgressCourses();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters([
      "allEnrolledCourses",
      "allCredentials",
      "allCompletedCourses",
      "getCredential",
      "language",
      "currentServerTime",
      "inProgressCourses",
      "startingSoonCourses",
      "archivedCourses",
      "getLayout",
      "language"
    ]),
    courses() {
      switch (this.tabIndex) {
        case 0:
          return this.inProgressCourses;
        case 1:
          return this.startingSoonCourses;
        case 2:
          return this.allCompletedCourses;
        case 3:
          return this.archivedCourses;
        default:
          return [];
      }
    },
    mutationString() {
      switch (this.tabIndex) {
        case 0:
          return `SET_INPROGRESS_COURSES`;
        case 1:
          return "SET_STARTINGSOON_COURSES";
        case 2:
          return "SET_COMPLETED_COURSES";
        case 3:
          return "SET_ARCHIVED_COURSES";
        default:
          return [];
      }
    }
  },
  watch: {
    language(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setTabContent();
      }
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 575) {
        this.getStartingSoonCourses();
        this.getCompletedCourses();
        this.getArchivedCourses();
      }
    },
    onCard(c) {
      // Commented for #TJLS-1047 since the redirection to course detail is allowed in this userstory https://extranet.who.int/jira/browse/TJLS-1047.
      // if (t.tab_id != "co")
      this.$router.push({ name: "Courses Detail", params: { id: c.id } });
    },
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    },
    getInProgressCourses() {
      this.$store.dispatch("getInProgressCourses").then(() => {
        this.isLoading = false;
      });
    },
    getStartingSoonCourses() {
      this.$store.dispatch("getStartingSoonCourses").then(() => {
        this.isLoading = false;
      });
    },
    getCompletedCourses() {
      this.$store.dispatch("getCompletedCourses").then(() => {
        this.isLoading = false;
      });
    },
    getArchivedCourses() {
      this.$store.dispatch("getArchivedCourses").then(() => {
        this.isLoading = false;
      });
    },
    setTabContent() {
      this.tabs = [
        {
          name: this.$t("my_learning.tab.in_progress"),
          active: true,
          type: "in_progress",
          tabId: "ip"
        },
        {
          name: this.$t("my_learning.tab.starting_soon"),
          active: false,
          type: "default",
          tabId: "ss"
        },
        {
          name: this.$t("my_learning.tab.completed"),
          active: false,
          type: "default",
          tabId: "co"
        },
        {
          name: this.$i18n.t("my_learning.tab.archived"),
          active: false,
          type: "default",
          tabId: "ar"
        }
      ];
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
